<template>
  <div class="h-full w-full p-5">
    <div class="flex">
      <div class="ml-3">
        <div>
          <dx-form :form-data="employee">
            <template #avatar-template="{}">
              <div class="form-avatar" />
            </template>
            <dx-group-item :col-count="4" css-class="first-group">
              <dx-simple-item template="avatar-template" />
              <dx-group-item :col-span="3">
                <dx-simple-item data-field="FirstName" />
                <dx-simple-item data-field="LastName" />
                <dx-simple-item
                  :editor-options="birthDateOptions"
                  data-field="BirthDate"
                  editor-type="dxDateBox"
                />
              </dx-group-item>
            </dx-group-item>
            <dx-group-item :col-count="2" css-class="second-group">
              <dx-group-item>
                <dx-simple-item data-field="Address" />
                <dx-simple-item data-field="City" />
                <dx-simple-item
                  :editor-options="positionOptions"
                  data-field="Position"
                  editor-type="dxSelectBox"
                />
              </dx-group-item>
              <dx-group-item>
                <dx-simple-item
                  :editor-options="stateOptions"
                  data-field="State"
                  editor-type="dxSelectBox"
                />
                <dx-simple-item data-field="ZipCode" />
                <dx-simple-item
                  :editor-options="phoneOptions"
                  data-field="Mobile"
                >
                  <dx-label text="Phone" />
                </dx-simple-item>
              </dx-group-item>
              <dx-simple-item
                :col-span="2"
                :editor-options="notesOptions"
                data-field="Notes"
                editor-type="dxTextArea"
              />
            </dx-group-item>
            <template #test-template="{}">
              <h3 style="color: red">test templte</h3>
            </template>
          </dx-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import { defineComponent, ref } from "vue";
import { reactive, watch } from "vue";
import createDataSource from "@/utils/devextreme";
import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
} from "devextreme-vue";
import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
} from "devextreme-vue/form";
import TailSelect from "tail.select";
export default defineComponent({
  components: {
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxLabel,
  },
  props: {
    // data: { type: Object, required: true },
  },
  setup(props, { emit }) {
    // const model = CloudFun.current?.model;
    // const readOnly = ref(false);
    // const treeViewName = ref({});
    // const categoryDataSource = createDataSource(
    //   model,
    //   "Id",
    //   "productCategory/query"
    // );

    const employee = reactive({
      ID: 1,
      FirstName: "John",
      LastName: "Heart",
      Position: "CEO",
      BirthDate: "1964/03/16",
      HireDate: "1995/01/15",
      Notes:
        "John has been in the Audio/Video industry since 1990. He has led DevAV as its CEO since 2003.\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.",
      Address: "351 S Hill St.",
      City: "Los Angeles",
      State: "CA",
      ZipCode: "90013",
      Home: "555-684-1334",
      Mobile: "555-684-1335",
      Email: "jheart@dx-email.com",
      Skype: "jheart_DX_skype",
    });

    const positions = [
      "HR Manager",
      "IT Manager",
      "Controller",
      "CEO",
      "Sales Manager",
      "Support Manager",
      "Shipping Manager",
    ];

    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    const birthDateOptions = { width: "100%" };
    const positionOptions = {
      items: positions,
      value: "",
    };
    const stateOptions = {
      items: states,
    };
    const phoneOptions = { mask: "+1 (000) 000-0000" };
    const notesOptions = { height: 140 };

    return {
      employee,
      states,
      positions,
      birthDateOptions,
      positionOptions,
      stateOptions,
      phoneOptions,
      notesOptions,
    };
  },
});
</script>

<style scoped>
.first-group,
.second-group {
  padding: 20px;
}
.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}
.form-avatar {
  height: 128px;
  width: 128px;
  margin-right: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background-image: url("https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/petersmith.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
